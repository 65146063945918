<template>
	<div class="order-con">
		<Nav navType="backnav" textCon="确认订单"></Nav>
		<!--有收货地址-->
		<div class="address-main" v-if="!initAddress" @click="goAddress">
			<div class="address-left">
				<span class="iconfont iconaddress"></span>
			</div>
			<div class="address-text">
				<span class="address-text-title">
					{{addressObj.name}}
					<span style="font-size:12px;color:#8C8C8C">{{addressObj.mobile}}</span>
				</span>
				<span>{{addressObj.address}}</span>
			</div>
			<span class="iconfont iconqianjin"></span>
		</div>
		<!--无收货地址-->
		<div class="no-address"  v-if="initAddress">
			<div class="no-address-con" @click="goAddress">
				<span class="iconfont iconaddress"></span>
				<span>请选择收货地址</span>
			</div>
			<span class="iconfont iconqianjin"></span>
		</div>

		<div class="type-pay">
			<Cell
				title="支付方式"
				is-link
				value="线上支付"
				style="border-radius: 6px;"
			></Cell>
		</div>
		<!--购物列表-->
		<div class="buy-list">
			<div class="shopping-item">
				<div class="shopping-item-top">
					<div class="shopping-item-top-left">
						<span class="iconfont iconshops"></span>
						<span v-if="storeinfo">{{storeinfo.name}}</span>
						<span v-else>平台自营</span>
						<span class="iconfont iconqianjin"></span>
					</div>
				</div>
				<!-- 购物车进入 -->
				<div v-if="state">
					<div class="shopping-item-main" v-for="(item, index) in goodsInfo" :key="index">
						<div class="shopping-item-main-img">
							<img :src="item.image[0]" alt />
						</div>
						<div class="shopping-item-main-right">
							<span>{{item.goods_name}}</span>
							<span>{{item.spes_desc}}</span>
							<div class="shopping-item-main-right-price">
								<span>¥{{item.price}}</span>
							</div>
						</div>
						<div class="shopping-num">
							<span>￥{{item.price}}</span>
							<br />
							<span>x{{item.num}}</span>
						</div>
					</div>
				</div>
				<!-- 商品页面进入 -->
				<div class="shopping-item-main" v-else>
					<div class="shopping-item-main-img">
						<img :src="goodsInfo.image" alt />
					</div>
					<div class="shopping-item-main-right">
						<span>{{goodsInfo.goods_name}}</span>
						<span>{{goodsInfo.spes_desc}}</span>
						<div class="shopping-item-main-right-price">
							<span>¥{{goodsInfo.price}}</span>
						</div>
					</div>
					<div class="shopping-num">
						<span>￥{{goodsInfo.price}}</span>
						<br />
						<span>x{{goodsInfo.num}}</span>
					</div>
				</div>
				<!-- <div class="order-info" @click="showModel.delivery = !showModel.delivery">
					<span>配送方式</span>
					<div class="order-info-main">
						<span>商家配送 免运费</span>
					</div>
					<span class="iconfont iconmore"></span>
				</div> -->
        <div class="order-info">
					<span>邮寄</span>
					<div class="order-info-main">
						<span>{{freight ? `${freight}元`:"商家免邮"}}</span>
					</div>
					<span class="iconfont iconmore"></span>
				</div>
				<!-- <div class="order-info">
					<span>每减优惠</span>
					<div class="order-info-main">
						<span>优惠￥100</span>
					</div>
					<span class="iconfont iconmore"></span>
				</div> -->
				<div class="order-info" @click="showModel.remark = !showModel.remark">
					<span>订单备注</span>
					<div class="order-info-main messAge">
						<span class="">{{message}}</span>
					</div>
					<span class="iconfont iconmore"></span>
				</div>
				<div class="order-bottom">
					<span>共1件</span>
					<span> <span style="color:#333333">小计:</span> ￥{{myprice}}</span>
				</div>
			</div>
		</div>
		<div class="order-push">
			<div class="order-push-left">
				<span v-if="!state">共计{{goodsInfo.num}}件商品</span>
				<span v-else>共计{{goodsNum}}件商品</span>
				<span>应付合计: <span style="color:#FF8000">￥{{myprice}}</span> </span>
			</div>
			<div @click="submitOrder()">提交订单</div>
		</div>
		<!--支付方式-->
		<Popup v-model="showModel.pay" closeable position="bottom" :style="{ height: '40%' }" :close-on-click-overlay="false" >
			<!-- @closed="submitBill" -->
			<div class="pay-type"> 支付方式 </div>
			<div class="pay-type-item">
				<div :class="payIdx == idx ? 'active':''" v-for="(item, idx) in paytype" :key="idx" @click="setPay(idx)">{{item}}</div>
			</div>
			<span v-if="payIdx == 3 ? false:true" style="margin-left: 10px">支持支付宝、微信、余额、VIP乐通卡等在线支付</span>
			<span v-if="payIdx == 3" style="margin-left: 10px">支持用户线下通过网银转账方式进行支付</span>
			<div class="make-btn" @click="payMon()">
				<span>确定</span>
			</div>
		</Popup>
		<!--配送方式-->
		<Popup v-model="showModel.delivery" closeable position="bottom" :style="{ height: '40%' }">
			<div class="pay-type"> 配送方式 </div>
			<RadioGroup v-model="deliveryType">
				<Cell
					v-for="(item, idx) in list"
					clickable
					:key="item"
					:title="item"
					@click="toggle(idx)"
				>
					<Radio
						:name="item"
						ref="checkboxes"
						slot="right-icon"
					/>
				</Cell>
			</RadioGroup>
			<div class="make-btn">
				<span>确定</span>
			</div>
		</Popup>
		<!--留言-->
		<Popup v-model="showModel.remark" closeable position="bottom" :style="{ height: '40%' }">
			<div class="pay-type"> 留言 </div>
			<Cell-group>
				<Field
					v-model="message"
					rows="2"
					:autosize="autosize"
					label="留言"
					type="textarea"
					maxlength="150"
					placeholder="请输入留言"
					show-word-limit
				/>
			</Cell-group>
			<div class="make-btn" @click="confirmMessage">
				<span>确定</span>
			</div>
		</Popup>
		<!--支付-->
		<PayMoney v-if="payModel" ref="pay" :paytype="payIdx" :ordersn="ordersn" :money="myprice"></PayMoney>

	</div>
</template>
<script>
import { Cell, Popup, RadioGroup, Radio, Field, CellGroup } from "vant";
import PayMoney from "@components/public/PayMoney";
import { getWxConfig } from "@/utils/wxpay";
import Nav from "@components/public/searchTop";
import Address from "@/api/address";
import Setting from '@/api/setting';
import Order from "@/api/order";
import Goods from "@/api/goods";
export default {
	components: {
		Nav,
		Cell,
		Popup,
		Radio,
		Field,
		PayMoney,
		CellGroup,
		RadioGroup
	},
	computed: {
		myprice: function() {
			if (this.state) {
				return Number(this.$route.query.priceSum);
			} else {
				return ((this.goodsInfo.num * this.goodsInfo.price) + Number(this.freight)).toString();
			}
		}
	},
	data() {
		return {
			addressObj: {},
			goodsInfo: {},
			payIdx: 0,
			ordersn: "",
      deliveryType: '快递',
      freight: "",
			message: "",
			list: ['快递', '门店自提', '无需快递'],
			paytype: ['支付宝', '微信', '余额'],
			initAddress: false,
      payModel: false,
      nowfreight: "", // 运费
			autosize: {
				minHeight: 100
			},
			expressIdx: 0,
			showModel: {
				pay: false,
				delivery: false,
				remark: false,
				pay_status: true
			},
			state: "",//false是立即购买，true是购物车结算
			goodsNum: 0, // 商品数量,
			carId: "",
			addressId: "",
			storeinfo: {}
		}
  },
	methods: {
		confirmMessage() {
			this.$set(this.showModel, "remark", false);
		},
		submitBill() {
			this.$router.replace({
				path: "/orderDetails",
				query: {
					ordersn: this.ordersn
				}
			})
		},
		// 提交订单
		submitOrder() {
			this.$set(this.showModel, "pay", true)
			if(this.state) {
				this.submitCar();
			} else {
				this.pushOrder();
			}
		},
		showPayToast() {
			this.showModel.pay = false;
			if (this.payIdx == 2) {
				this.$nextTick(() => {
					this.$refs.pay.showPayToast();
				}, 100)
			}
		},
		goAddress() {
			this.$router.push('/addresslist');
			// this.$router.push({
			// 	path: "/addresslist",
			// 	query: this.$route.query
			// })
		},
		setPay(idx) {
			this.payIdx = idx;
		},
		toggle(index) {
			this.expressIdx = index;
      this.$refs.checkboxes[index].toggle();
    },
		// 点击立即购买展示页面
    async goShowPage() {
      try {
        const res = await Order.goBuyNow();
        console.log(res);
      } catch(err) {
        console.log(err);
      }
    },
		// 获取默认地址信息
		async getInitAddressInfo() {
			try {
				const res = await Address.getUserInitAddress();
				if (res.code == 400) {
					this.initAddress = true;
				} else {
					console.log(res.data);
					this.addressObj = res.data;
				}
			} catch (err) {
				console.log(err);
			}
		},
		// 获取商品信息
		async getGoodsInfo() {
			try {
				const { goodsid, productid, num }  = this.$route.query;
				const res = await Order.goBuyNow(goodsid, productid, num);
				if (res.code == 200) {
					this.storeinfo = res.data.store;
          this.goodsInfo = res.data.goods;
          this.freight = res.data.freight;
					this.payModel = true;
          console.log(this.goodsInfo);
          // 获取运费
          this.getNowfreight( this.goodsInfo.goods_id, this.addressId, this.goodsInfo.num);
				}
			} catch(err) {
				console.log(err);
			}
		},
		// 提交订单
		// app_id:应用id
		// token:登陆态
		// source:来源 1=PC页面 2=H5页面 3=微信小程序
		// order_type:订单类型 1、普通订单
		// receipt_type:收货方式 1、快递 2、门店自提 3、无需快递
		// address_id:地址id（收货方式为快递）
		// store_id:提货门店id（门店自提）
		// lading_name:提货人姓名（门店自提）
		// lading_mobile:提货人手机号码（门店自提）
		// goods_id:商品id
		// products_id:商品规格id
		// buy_num:购买数量
		// message:买家备注
		async pushOrder() {
			try {
				const res = await Order.goBuyOrder(
					'2',
					'1',
					this.expressIdx + 1,
					this.addressObj.id,
					'',
					'',
					'',
					this.goodsInfo.goods_id,
					this.goodsInfo.products_id,
					this.goodsInfo.num,
					this.message
				);
				if (res.code == 200) {
					setTimeout(() => {
						this.ordersn = res.data.ordersn;
					}, 500)
				}
				this.$toast(res.msg);
			} catch (err)  {
				console.log(err);
			}
    },
    // 获取运费
    async getNowfreight(goods_id, address_id) {
      // goods_id:商品id
      // address_id:用户地址id
      // num:购买数量
      try {
        const res = await Setting.getUserFreight(goods_id, address_id, this.goodsInfo.num);
        if (res.code == 200) {
          this.freigh = res.data.freight;
        }
      } catch (error) {
        console.log(error);
      }
    },
		// 购物车提交订单
		async submitCar() {
			let list = {
				100: {
					cart_id: this.carId,
					order_type: "1",
					receipt_type: "1",//收货方式
					message: this.message
				}
			}
			try {
				const res = await Order.carCreate(
					"2",
					"",
					this.addressObj.id,
					list
				)
        if (res.code == 200) {
          this.ordersn = res.data.ordersn;
        } else {
          this.$toast("订单提交失败...");
          return;
        }
			} catch(err) {
				console.log(err);
			}
		},
    // 支付
    payMon() {
      if (this.payIdx == 0) {
        console.log('支付宝');
      } else if (this.payIdx == 1) {
        getWxConfig(this.ordersn);
      } else if (this.payIdx == 2) {
        // this.showModel.pay = false;
        this.$refs.pay.showPayToast();
      }
		},
		// 购物车结算
		async getCarOrder() {
			this.carId = this.$route.query.idArr;
			this.goodsNum = this.$route.query.goodsNum
			let arr = this.$route.query.idArr;
			try {
				const res = await Goods.clearingPrice(arr);
				if (res.code == 200) {
					console.log(res)
					this.storeinfo = res.data[0].storeinfo;
					this.goodsInfo = res.data[0].goods;
					this.payModel = true;
					console.log(this.goodsInfo);
				}
			} catch(err) {
				this.$toast(err);
			}
		},
		async getAddress() {
			try {
        const res = await Address.getAddressDet(this.addressId);
        if (res) {
          this.addressObj = res;
        }
			} catch (err) {
				console.log(err)
			}
    },
  },
  mounted() {
    this.$nextTick(() => {
      // 是否切换了地址
      this.addressId = this.$store.state.user.addressId;
      if(this.addressId) {
        this.getAddress();
      } else {
        this.getInitAddressInfo();
      }
      // 是否在购物车进入
      this.state = this.$route.query.hasOwnProperty("idArr");
      console.log(this.state);
      if(this.state) {
        this.getCarOrder()
      } else {
        this.getGoodsInfo()
      }
    });
  }
};
</script>
<style lang="scss" scoped>
.order-con {
	width: 100%;
	height: 100vh;
	padding: 10px 0;
	background: #f4f4f4;
	.no-address {
		width: 94%;
		height: 100px;
		display: flex;
		padding: 0 10px;
		justify-content: space-between;
		align-items: center;
		background: #ffffff;
		margin: 0 auto;
		.no-address-con {
			width: 80%;
			height: 60px;
			display: flex;
			padding-left: 58px;
			flex-direction: column;
			align-items: center;
			justify-content: space-between;
			span:nth-child(1) {
				font-size: 22px;
			}
		}
	}
	.address-main {
		width: 94%;
		height: 64px;
		display: flex;
		margin: 0 auto;
		display: flex;
		padding: 0 10px;
		align-items: center;
		border-radius: 6px;
		background: #ffffff;
		justify-content: space-between;
		.address-left {
      width: 30px;
      text-align: center;
      span {
        font-size: 20px;
      }
    }
		.address-text {
			width: 70%;
			display: flex;
			flex-direction: column;
			.address-text-title {
				font-size: 16px;
				color: #333333;
			}
		}
	}
	.type-pay {
		width: 95%;
		height: auto;
		margin: 10px auto;
	}
	.make-btn {
		position: absolute;
		bottom: 0;
		width: 96%;
		height: 35px;
		line-height: 35px;
		color: #ffffff;
		text-align: center;
		background: linear-gradient(-90deg,rgba(255,128,0,1) 0%,rgba(255,177,1,1) 100%);
		left: 50%;
		font-size: 16px;
		border-radius: 6px;
		margin-left: -48%;
		margin-bottom: 10px;
	}
	.pay-type {
		width: 100%;
		height: 45px;
		font-size: 16px;
		color: #333333;
		line-height: 45px;
		text-align: center;
		border-bottom: 1px solid #dfdfdf;
	}
	.pay-type-item {
		width: 100%;
		height: 60px;
		display: flex;
		margin-top: 10px;
		div {
			width: 72px;
			height: 26px;
			line-height: 26px;
			text-align: center;
			border-radius: 20px;
			margin-left: 10px;
			color: #8C8C8C;
			border: 1px solid #8C8C8C;
		}
		.active {
			color: #FF8000;
			border: 1px solid #FF8000;
		}
	}
	.order-push {
		position: fixed;
		left: 0;
		bottom: 0;
		width: 100%;
		height: 45px;
		display: flex;
		padding: 0 10px;
		background: #ffffff;
		align-items: center;
		justify-content: space-between;
		.order-push-left {
			span:nth-child(2) {
				margin-left: 10px;
			}
		}
		div:nth-child(2) {
			width: 80px;
			height: 32px;
			text-align: center;
			line-height: 32px;
			color: #ffffff;
			border-radius: 16px;
			background: linear-gradient(
				-90deg,
				rgba(255, 128, 0, 1) 0%,
				rgba(255, 177, 1, 1) 100%
			);
		}
	}
	.buy-list {
		width: 100%;
		height: auto;
		.shopping-item {
			width: 94%;
			height: auto;
			display: flex;
			padding: 0 10px;
			border-radius: 4px;
			background: #ffffff;
			flex-direction: column;
			margin: 5px auto;
			.shopping-item-top {
				width: 100%;
				height: 36.5px;
				display: flex;
				color: #333333;
				justify-content: space-between;
				align-items: center;
				.shopping-item-top-left {
					width: 86%;
					height: 100%;
					display: flex;
					align-items: center;
					justify-content: flex-start;
					span {
						margin-left: 6px;
					}
				}
				.shopping-item-top-type {
					color: #ff8000;
				}
			}
			.shopping-item-main {
				width: 100%;
				height: 100px;
				display: flex;
				align-items: center;
				justify-content: space-between;
				.shopping-item-main-img {
					width: 84px;
					height: 84px;
					margin-left: 6px;
					img {
						width: auto;
						height: auto;
						max-width: 100%;
						max-height: 100%;
						border-radius: 6px;
					}
				}
				.shopping-item-main-right {
					width: 220px;
					height: 100%;
					display: flex;
					padding: 10px;
					flex-direction: column;
					justify-content: space-between;
					.shopping-item-main-right-price {
						flex: 1;
						font-size: 14px;
						align-items: center;
						display: flex;
						color: #ff8000;
						justify-content: space-between;
					}
				}
				.shopping-num {
					width: auto;
					height: 100%;
					padding-top: 10px;
					text-align: right;
					span:nth-child(1) {
						font-size: 14px;
						color: #333333;
					}
					span:nth-child(2) {
						font-size: 12px;
						color: #999999;
					}
				}
			}
			.order-info {
				width: 100%;
				height: 30px;
				line-height: 30px;
				display: flex;
				font-size: 12px;
				color: #333333;
				justify-content: space-between;
				.order-info-main {
					flex: 1;
					display: flex;
					margin-right: 10px;
					margin-left: 10px;
					flex-direction: column;
					text-align: right;
				}
				.messAge {
					overflow:hidden;
					text-overflow:ellipsis;
					white-space:nowrap;
				}
			}
			.order-bottom {
				line-height: 30px;
				text-align: right;
				span:nth-child(1) {
					color: #8c8c8c;
					margin-right: 10px;
				}
				span:nth-child(2) {
					color: #ff8000;
				}
			}
		}
	}
}
</style>
